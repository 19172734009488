import { createContext, useEffect, useState } from "react";
import Notification from "../components/Notification";

export const NotificationContext = createContext({
    notifications: null,
    addNotification: () => {},
    removeNotification: () => {}
})

export const NotificationProvider = ({children}) => {
    const [notifications, setNotifications] = useState([])

    useEffect(() => {
        console.log(notifications)
    }, [notifications])

    const addNotification = ({ status, title, message}) => {
        setNotifications([
            ...notifications,
            {
                key: new Date().getTime(),
                status: status,
                title: title,
                message: message
            }
        ])
    }

    const removeNotification = (index) => setNotifications(notifications.filter((n, i) => i !== index))      

    return <NotificationContext.Provider value={{
        notifications,
        addNotification,
        removeNotification
    }}>
        <>
            <div className='notification-wrapper'>
                { notifications.map((item, key) => <Notification key={item.key} index={item.key} {...item} />) }
            </div>
            {children}
        </>
    </NotificationContext.Provider>
}