/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { createRef, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { CSSTransition } from "react-transition-group"
import { useOnClickOutside } from './Functions'
import { usePopper } from 'react-popper';


export default function DropDown(props){
    const { 
        name,
        value, 
        onChange, 
        placeholder, 
        classes, 
        disabled, 
        multiple,
        parentSelectable,
        valid,
        options,
        containerClass,
        selectFirst,
        sideFlex,
        label,
        blank,
        unit,
        formik,
        renderOption,
        optionClass
    } = props

    const [selected, setSelected] = useState(null)
    const [open, setOpen] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState(options)
    const [limit, setLimit] = useState(5)
    const [width, setWidth] = useState('auto')

    const searchRef = useRef()
    const inputRef = useRef()
    const searchFormRef = useRef()
    const listRef = useRef()
    

    /** POPPER DROPDOWN OPTIONS */
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
        modifiers: [
        ]
    });

    const error = formik && formik.touched[name] && Boolean(formik.errors[name])
    const helperText = formik && formik.touched[name] && formik.errors[name]
    const touched = formik && formik.touched[name]

    useEffect(() => {
        if(value){
            setSelected(options.find(v => v.value === value))
        }
    }, [value, options])

    useEffect(() => {
        if(options && options.length){
            setFilter([...options.slice(0, limit)])
        }
    }, [options])

    useEffect(() => {
        if(options && options.length) setFilter([...options.slice(0, limit)])
    }, [limit])
    
    useEffect(() => {
        if(open){
            setIsOpen(true)
        }else{
            setTimeout(() => {
                setIsOpen(false)
            }, 245)
        }
        
    }, [open])
    

    useEffect(() => {
        if(referenceElement?.offsetWidth && width === 'auto'){
            setWidth(referenceElement.offsetWidth)
        }
    }, [referenceElement])

    useEffect(() => {
        if(isOpen){
            if(searchRef.current){
                searchRef.current.focus()
            }
        }
    }, [isOpen])

    const filterRef = useRef(filter); // Oppretter en ref for å lagre den nyeste filter-verdien

    // Oppdater ref hver gang filter endres
    useEffect(() => {
        filterRef.current = filter;
    }, [filter]);

    const ref = useRef()

    useOnClickOutside(ref, () => setOpen(false))

    useEffect(() => {
        
        if(!search.length){
            if(options && options.length) setFilter([...options.slice(0, limit)])
            return
        }
        
        setFilter([...options].filter(option => {
            
            if(option.items){
                const newItems = [...option.items]
                const newItemsFilter = newItems.filter(item => {
                    return item.label.match(new RegExp(search, 'i'))
                })

                if(newItemsFilter.length > 0){
                    return {
                        ...option,
                        items: newItemsFilter
                    }
                }
            }
            let bool = false
            Object.keys(option).forEach((obj, key) => {
                if(typeof option[obj] === 'string' && option[obj].match(new RegExp(search, 'i'))) bool = true
            })

            return bool
        }).slice(0, 20))
    }, [search])

    const handleScroll = (e) => {
        if(!search.length){
            if((e.target.scrollTop + e.target.offsetHeight) === e.target.scrollHeight){
                setLimit(limit + 5)
            }
        }
    }

    const Option = (props) => {
        const { label, icon, description, value } = props
        if(multiple){
            
            const active = value.filter(t => t.value === props.value)
            
            return (
                <li 
                    className={`row ${active.length !== 0 ? 'active' : ''} ${optionClass ? optionClass : ''}`} 
                    onClick={() => {
                        
                        if(value.filter(u => u.value === props.value).length){
                            const newValue = value.filter((t) => t.value !== props.value)
                            
                            onChange(newValue)
                        }else{
                            onChange([...value, props])
                        }
                        
                    }}
                >
                    <span className="col">{label}</span>
                    { icon ? <span className="col-auto"><FontAwesomeIcon size="lg" icon={icon} /></span> : null}
                    { description ? <small className="bold">{description}</small> : null}
                </li>
            )
        }
        return (
            <li 
                className={`${selected && selected.value === value ? 'active' : ''}  ${optionClass ? optionClass : ''}`} 
                onClick={() => {
                    setOpen(false)
                    onChange(value)
                }}
            >
                { renderOption ? renderOption(props) : 
                <div className="row">
                    <span className="col">{label}</span>
                    { icon ? <span className="col-auto"><FontAwesomeIcon size="lg" icon={icon} /></span> : null}
                    { description ? <small className="bold">{description}</small> : null}
                </div>
                }
            </li>
        )
    }

    const SelectedOption = (props) => {
        const { label, description } = props

        return (
            <div className="col-auto mb-2">
                <div className="row gx-0" style={{padding: '5px 10px', backgroundColor: 'var(--primary)', color: '#FFF', borderRadius: '30px'}}>
                    <div className="col-auto">
                        {label}
                        { description ? <small className="bold">{description}</small> : null}
                    </div>
                    <div className="col-auto">
                        <FontAwesomeIcon onClick={() => onChange(props, inputRef?.current)} className="pointer" style={{marginLeft: 10}} icon={['fal', 'xmark']} />
                    </div>
                </div>
            </div>
        )
    }

    const ParentOption = (props) => {
        const { label, icon, description, items } = props
        if(multiple){
            
            const active = value.filter(t => t.value === props.value)
            
            return (
                <li 
                    className={`row ${active.length !== 0 ? 'active' : ''}`} 
                    onClick={() => {
                        
                        if(value.filter(u => u.value === props.value).length){
                            const newValue = value.filter((t) => t.value !== props.value)
                            
                            onChange(newValue)
                        }else{
                            onChange([...value, props])
                        }
                        
                    }}
                >
                    <span className="col">{label}</span>
                    { icon ? <span className="col-auto"><FontAwesomeIcon size="lg" icon={icon} /></span> : null}
                    { description ? <small className="bold">{description}</small> : null}
                    {
                        items && items.length ? 
                        (
                            <ul>
                                { items.map((v, k) => <Option key={k} {...v} />)}
                            </ul>
                        )
                        : null
                    }
                </li>
            )
        }
        return (
            <li className="dropdown-parent-row">
                <div 
                    className={`dropdown-parent-block row ${value && value.value === props.value ? 'active' : ''}`}
                    onClick={() => {
                        setOpen(false)
                        onChange(props)
                    }}
                >
                    <span className="col">{label}</span>
                    { icon ? <span className="col-auto"><FontAwesomeIcon size="lg" icon={icon} /></span> : null}
                    { description ? <small className="bold">{description}</small> : null}
                </div>
                {
                        items && items.length ? 
                        (
                            <ul>
                                { items.map((v, k) => <Option key={k} {...v} />)}
                            </ul>
                        )
                        : null
                    }
            </li>
        )
    }

    const Parent = (props) => {
        const { title, icon, description, items } = props
        return (
            <li className="dropdown-parent-row">
                <div 
                    className={`dropdown-parent-block row bg-success`} style={{'--bs-bg-opacity': .5}}
                >
                    <span className="col bold">{title}</span>
                    { icon ? <span className="col-auto"><FontAwesomeIcon size="lg" icon={icon} /></span> : null}
                    { description ? <small className="bold">{description}</small> : null}
                </div>
                {
                        items && items.length ? 
                        (
                            <ul>
                                { items.map((v, k) => <Option key={k} {...v} />)}
                            </ul>
                        )
                        : null
                    }
            </li>
        )
        return (
            <li>
                <label className="p-2 border-bottom fw-bold d-block m-0 bg-grey-100">{props.title}</label>
                <ul>
                {
                    props.items?.map((item, key) => {
                        return (
                            <Option 
                                key={key}
                                {...item}
                            />
                        )
                    })
                }
                </ul>
            </li>
        )
    }

    return (
        <>
            <div tabIndex={0} className={`dropdown-container position-relative ${open ? 'focus ' : ''} ${sideFlex ? 'col-lg-6' : ''} ${disabled ? 'disabled' : ''} ${classes}`} ref={ref}>
                <div ref={setReferenceElement} className={`position-relative ${containerClass ? containerClass : ''}`} onClick={() => !disabled ? setOpen(!open) : null}>
                    {label ? <label>{label}</label> : null}
                    <div className={`dropdown input-group ${touched && !error ? 'valid' : ''} ${disabled ? 'disabled ' : ''} ${valid ? 'valid ' : ''} ${error ? 'error ' : ''}`}>  
                        
                        <div className="col me-2">
                        {!multiple ? 
                            (renderOption && selected ? 
                        
                                renderOption(selected)
                                : 
                            <>
                                <p>{ selected ?  (<span>{selected?.label}</span>)  : <span className="hint-text">{placeholder}</span> }</p>
        
                            </>
                            )
                        : 
                        (
                            <>
                                <p className="hint-text">{ selected && selected.length === 1 ? selected[0].label : selected && selected.length ? `${selected.length} ${unit ? unit : 'valgt'}` : placeholder }</p>
                                
                            </>
                            
                        )}
                        </div>
                        <FontAwesomeIcon icon={['fal', `chevron-${open ? 'up' : 'down'}`]} />
                    </div>
                </div>
                {(open || isOpen) && createPortal(
                    <div
                        className="popper popper-dropdown"
                        ref={setPopperElement}
                        style={styles.popper}
                        {...attributes.popper}
                    >
                        
                            <div className={`dropdown-options ${!open && isOpen ? 'closing' : 'opening'}`} style={{width: width}}>
                                <div className="p-2">
                                    <form className="m-0" ref={searchFormRef}>
                                        <input ref={searchRef} className="form-control" placeholder="Search.." value={search} onChange={(e) => setSearch(e.target.value)} />
                                    </form>
                                </div>
                                <ul ref={listRef} onScroll={handleScroll}>
                                    
                                    { blank && value ? 
                                        <li 
                                            className={`row ${!value ? 'active' : ''} ${optionClass ? optionClass : ''}`} 
                                            onClick={() => {
                                                setOpen(false)
                                                onChange(null)
                                            }}
                                        >
                                        <span className="col">{typeof blank === 'string' ? blank : 'All'}</span>
                                    </li>    
                                    : null}
                                    {
                                        filter && filter.map((item, key) => {
                                            if(!item) return
                                            if(item?.items) return parentSelectable ? <ParentOption key={key} {...item} /> : <Parent key={key} {...item} />
                                            return <Option key={key} {...item} />
                                            
                                        })
                                    }
                                </ul>
                            </div>
                       
                    </div>,
                    document.querySelector('#root')
                )}
                
            </div>
            {error && helperText ? <small className="text-danger">{helperText}</small> : null}
        </>
    )
}