import { useContext } from "react"
import { AuthContext } from "../providers/AuthProvider"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { drawProfileBubble } from "./Functions"
import ContextMenu, { ContextMenuItem } from "./ContextMenu"

export default function Header({ menuOpen, setMenuOpen }){
    const { user, APIURL, logout } = useContext(AuthContext)

    
    return (
        <header className="px-4 py-3 position-sticky">
            <div className="row justify-content-between align-items-center">
                <div className="col-auto d-md-none">
                    <button className="btn btn-link text-dark" onClick={() => setMenuOpen(!menuOpen)}>
                        <FontAwesomeIcon icon={['fas', menuOpen ? 'times' : 'bars']} size="xl"/>
                    </button>
                </div>
                <div className="col-md col-6">
                    
                        <div className="row align-items-center">
                            <div className="col-auto">
                            
                                <img src={`${process.env.PUBLIC_URL}/logo-sv-dark.svg`} height={56} style={{width: '100%'}} />
                            </div>
                        
                        <div className="col-auto"><h3 className="m-0 d-none d-md-block">Admin</h3></div>
                       
                    </div>
                </div>
                <div className="col-auto">
                {
                    user ? (
                        <ContextMenu
                            buttonEl={
                                
                                    <div className="row align-items-center gx-2">
                                        <div className="col-auto">
                                            { drawProfileBubble(user) }
                                        </div>
                                        <div className="col-auto">
                                        <div className="d-md-block d-none">{user.fullname}</div>
                                        </div>
                                        <div className="col-auto">
                                            <FontAwesomeIcon icon={['far', 'angle-down']} />
                                        </div>
                                    </div>
                            }
                        >
                            <ContextMenuItem 
                                label="Log out"
                                icon={['far', 'person-to-door']}
                                onClick={() => logout()}
                            />
                        </ContextMenu>
                    
                    ) : null
                }
                </div>
            </div>
        </header>
    )
}