import { useFormikContext } from "formik";
import Input from "./Input";

export default function FormikInput(props){
     
    const { name, label, type, formik} = props
    

    if(!formik){
        return <FormikContext {...props} />
    }
    return (
        <Input
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            touched={formik.touched[name]}
            helperText={formik.touched[name] && formik.errors[name]}
            {...props}
        />
    )
}

function FormikContext(props){
    const { name, label, type} = props
    const formik = useFormikContext()

    return (
        <Input
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            touched={formik.touched[name]}
            helperText={formik.touched[name] && formik.errors[name]}
            {...props}
        />
    )
}