import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContextMenu, { ContextMenuItem } from "../components/ContextMenu";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import PageLoading from "../components/PageLoading";
import { ago, channelCurrency, drawProfileBubble, drawStatus, formatDate, getOrdinal, status, statusBubble } from "../components/Functions";
import moment from "moment";
import Modal from "../components/Modal";
import { useFormik } from "formik";
import * as yup from 'yup';
import Input from "../components/Input";
import FormikInput from "../components/FormikInput";
import CheckBox from "../components/CheckBox";
import FileUpload from "../components/FileUpload";
import { useNavigate, useParams } from "react-router";
import { AuthContext } from "../providers/AuthProvider";
import { Link } from "react-router-dom";
import Rte from "../components/Rte";
import { Document, PDFDownloadLink, PDFViewer, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { confirm } from "../components/Confirmation";
import InvoiceFileInput from "../components/InvoiceFileInput";
import DropDown from "../components/Dropdown";
import InlineChat from "../components/InlineChat";



const personTemplate = {
    first_name: '',
    last_name: '',
    dob: '',
    phone: '',
    email: '',
    allergies: '',
    recipientofInvoice: false,
    streetAddress: '',
    postalCode: '',
    city: '',
    invoices: [],
    invoice_for: []
}

const Context = createContext()

export const Provider = ({children}) => {
    const { apiCall, sellers } = useContext(AuthContext)
    const params = useParams()

    const [data, setData] = useState(null)
    const [suppliers, setSuppliers] = useState([])
    const [profit, setProfit] = useState(0)

    useEffect(() => {

    }, [])

    const calculateProfit = (price, expenses) => {
        let actualPrice = null
        if(typeof price === 'string'){
            actualPrice = parseInt(price.replace(/\s/g, ''))
        }else{
            actualPrice = price
        }

        

        var negative = expenses.reduce((tot, arr) => { 
            return typeof arr.price === 'string' ? tot + parseInt(arr.price.replace(/\s/g, '')) : tot + arr.price
          },0)


        setProfit(actualPrice - negative)
    }

    useEffect(() => {
        getDetails()
        getSuppliers()
    }, [])

    const getDetails = async () => {
        const d = await apiCall({action: 'getBookingDetails', id: params.id})
        if(d.status === 1){
            
            setData(d.data)
        }else{
            status(d)
        }
    }

    const getSuppliers = async () => {
        const d = await apiCall({action: 'getSuppliers'})
        if(d.status === 1){
            
            setSuppliers(d.data)
        
        }else{
            status(d)
        }
    }
    
    return <Context.Provider value={{
        data,
        reload: () => getDetails(),
        suppliers,
        calculateProfit,
        profit
    }}>{data ? children : <PageLoading />}</Context.Provider>
}

export default function Booking(){
    return (
        <Provider>
            <Main />
        </Provider>
    )
}

function Main(){
    const { data, calculateProfit, profit, reload } = useContext(Context)
    const { apiCall, sellers } = useContext(AuthContext)
    const navigate = useNavigate()

    const [iframeKey, setIframeKey] = useState(0)
    const iframeRef = useRef()
    useEffect(() => {
        getUnreadMessages()
    }, [data])

    const [unreadMessages, setUnreadMessages] = useState(0)
    const [showPdf, setShowPdf] = useState(false)
    
    let schema = yup.object().shape({
        title: yup.string().required("A title is required"),
    });




    const formik = useFormik({
        initialValues: {
            title: data?.title,
            adults: data?.properties?.people?.adults,
            youth: data?.properties?.people?.youth,
            children: data?.properties?.people?.children,
            departure: data?.departure,
            price: data?.price,
            price_breakdown: data?.price_breakdown,
            price_freetext: data?.price_freetext ?? '',
            expiration_date: data?.expiration_date,
            comment: data?.comment,
            message: data?.message,
            persons: data?.persons && data?.persons.length ? data.persons : [],
            special_occation: data?.special_occation,
            other: data?.other,
            attachments: data?.attachments ?? [],
            flight_information_from: data?.flight_information?.from ?? '',
            flight_information_to: data?.flight_information?.to ?? '',
            arrival: data?.flight_information?.arrival,
            arrivalDayAfter: data?.flight_information?.arrivalDayAfter,
            expenses: data?.expenses ?? []
        },
        validationSchema: schema,
        onSubmit: async (values) => {  
            const d = await apiCall({
            
                action: 'editBooking',
                id: data.id,
                ...values
            })
            
            status(d)
            if(d.status === 1) reload()
        },
    });

    useEffect(() => {
        calculateProfit(formik.values.price, formik.values.expenses)
    }, [formik])

    const editBooking = () => {
        formik.submitForm()
    }

    const changeSeller = async (e) => {
        
        const d = await apiCall({action: 'changeAssignmentSeller', assignment: data.assignment_id, user: e.target.value})
        status(d)
    }

    const fulfillBooking = async () => {
        const d = await apiCall({
            action: 'fulfillBooking',
            id: data.id
        })
        status(d)
        if(d.status === 1) reload()
    }

    const addPerson = () => {
        const newPersons = formik.values.persons
        newPersons.push(personTemplate)
        formik.setFieldValue('persons', newPersons)
    }

    const addExpense = async () => {
        const d = await apiCall({action: 'newEmptyExpense', booking: data.id})
        status(d)
        
        if(d.status === 1){
            const newExpenses = formik.values.expenses
            newExpenses.push(d.data)
            formik.setFieldValue('expenses', newExpenses)
        }
    }

    const getUnreadMessages = async () => {
        const d = await apiCall({
            action: 'getUnreadMessagesBasedOnState', 
            assignment: data.assignment_id,
            state: {
                id: data.id,
                no: data.nr,
                type: 'Booking'
            }
        })

        setUnreadMessages(d)
    }

    const notifyDocument = async () => {
        const d = await apiCall({
            action: 'notifyBookingNewDocument', 
            id: data.id,
        })
        status(d)
    }

    const notifyInvoiceReady = async () => {
        const d = await apiCall({
            action: 'notifyBookingInvoiceReady', 
            id: data.id,
        })
        status(d)
    }

    const notifyInvoicePaid = async () => {
        const d = await apiCall({
            action: 'notifyBookingInvoicePaid', 
            id: data.id,
        })
        status(d)
    }

    const notifyDepartureSoon = async () => {
        const d = await apiCall({
            action: 'notifyBookingDepartureSoon', 
            id: data.id,
        })
        status(d)
    }

    const refreshIframe = () => {
        setIframeKey(iframeKey +1)
    }

    const deleteBooking = async () => {
        if(await confirm('Delete booking', 'Are you sure you wan\'t to delete this booking ?', {label: 'Yes, delete', type: 'danger'})){
            const d = await apiCall({action: 'deleteBooking', id: data.id})
            if(d.status === 1) navigate('/bookings')
            status(d)
        }
    }

    const wrapperRef = useRef()
    const mainRef = useRef()
    const pdfRef = useRef()

    const [pdfWidth, setPdfWidth] = useState(0)

    useEffect(() => {
        if(wrapperRef && mainRef){
            if(wrapperRef.current && mainRef.current){
                adjustPdfWidth()
            }
        }
    }, [wrapperRef, mainRef])

    const adjustPdfWidth = () => {
        if(wrapperRef && mainRef){
            if(wrapperRef.current && mainRef.current){
                setPdfWidth(wrapperRef.current.clientWidth - mainRef.current.clientWidth - 100)
            }
        }
    }

    window.addEventListener('resize', (event) => {
        adjustPdfWidth()
    })
   
    return (
        <div className="bg-light-grey" ref={wrapperRef}>
            <div className="row justify-content-center">
                <div className="col-auto">
                    <div className="page bg-white px-md-5 px-3 py-3 mb-4" ref={mainRef}>
                        <div className="row gx-0 mb-5 bg-white" style={{position: 'sticky', top: 0, zIndex: 9}}>
                            <div className="col-md mb-4 mb-md-0">
                                <h3>Booking #{data.nr} </h3>
                                <div className="row gx-2 align-items-center mb-2">
                                    <div className="col-auto">{moment(data.createdon).format('MMMM DD. YYYY')}</div>
                                    <div className="col-auto"><FontAwesomeIcon icon={['fas', 'circle']} size="xs" style={{transform: 'scale(.5)'}} /></div>
                                    <div className="col-auto">{
                                        sellers ? 
                                            <select class="special-select" onChange={changeSeller} defaultValue={data.seller?.id}>
                                            {  sellers.map((s, k) => <option key={k} selected={s.id === data.seller?.id} value={s.id}>{s.fullname}</option>) }
                                        </select>
                                        : data.seller?.fullname
                                    }</div>
                                </div>
                                <div className="row gx-2">
                                    <div className="col-auto">
                                        {statusBubble(data.status)}
                                    </div>
                                    <div className="col-auto">
                                        {statusBubble(data.payment_status)}
                                    </div>
                                </div>
                                
                                
                            </div>
                            <div className="col-auto">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-auto">
                                        <button className="btn btn-sm btn-grey" onClick={() => editBooking()}>Save</button>
                                    </div>
                                    <div className="col-auto">
                                        <button className="btn btn-sm btn-grey" onClick={() => fulfillBooking()}>
                                            {{
                                                'unfulfilled': 'Fulfil',
                                                'fulfilled': 'Set Confirmed',
                                                'confirmed': 'Set Ready to go',
                                                'ready': 'Reset to Unfulfilled'
                                            }[data.status]}
                                        </button>
                                    </div>
                                    <div className="col-auto">
                                        <ContextMenu
                                                buttonEl={<button class="btn btn-sm btn-grey">
                                                <div className="row gx-2">
                                                    <div className="col-auto">More actions</div>
                                                    <div className="col-auto"><FontAwesomeIcon icon={['far', 'chevron-down']} size="xs" /></div>
                                                </div>
                                            </button>}
                                            >
                                                <ContextMenuItem
                                                    label="Booking summary"
                                                    icon={['far', 'page']}
                                                    onClick={() => setShowPdf(!showPdf)}
                                                />
                                                <ContextMenuItem
                                                    label="Notify customer: New document"
                                                    icon={['far', 'envelope']}
                                                    onClick={() => notifyDocument()}
                                                />
                                                <ContextMenuItem
                                                    label="Notify customer: Invoice ready"
                                                    icon={['far', 'envelope']}
                                                    onClick={() => notifyInvoiceReady()}
                                                />
                                                <ContextMenuItem
                                                    label="Notify customer: Invoice paid"
                                                    icon={['far', 'envelope']}
                                                    onClick={() => notifyInvoicePaid()}
                                                />
                                                <ContextMenuItem
                                                    label="Notify customer: Ready for departure?"
                                                    icon={['far', 'envelope']}
                                                    onClick={() => notifyDepartureSoon()}
                                                />
                                                <hr style={{margin: 0}} />
                                                <ContextMenuItem 
                                                    label="Delete"
                                                    icon={['far', 'trash']}
                                                    onClick={() => deleteBooking()}
                                                    colorClass="text-danger"
                                                />
                                            </ContextMenu>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} className="pb-5">
                            <div className="row gx-5">
                                <div className="col">
                                    <div class="mb-3">
                                        <FormikInput 
                                            name="title"
                                            label="Title"
                                            formik={formik}
                                        />
                                    </div>
                                    <div class="mb-3">
                                        <div className="row gx-2">
                                            <div className="col-md-4">
                                                <FormikInput 
                                                    name="adults"
                                                    label="Adults (16+)"
                                                    formik={formik}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <FormikInput 
                                                    name="youth"
                                                    label="Young adults (12-15)"
                                                    type="number"
                                                    formik={formik}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <FormikInput 
                                                    name="children"
                                                    label="Children (0-11)"
                                                    type="number"
                                                    formik={formik}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div className="row">
                                            <div className="col-md-7">
                                                <FormikInput 
                                                    name="departure"
                                                    label="Departure date"
                                                    type="date"
                                                    formik={formik}
                                                />
                                            </div>
                                            <div className="col">
                                                <FormikInput 
                                                    name="price"
                                                    label="Total price"
                                                    formik={formik}
                                                    prefix={channelCurrency(data.channel)}
                                                    mask={(value) => {
                                                        if(value){
                                                            value = value.toString().replace(/\D/g, "");
                                                            const newValue = new Intl.NumberFormat('nb-NO').format(value)
                                                            return newValue
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                   
                                        <div class="mb-3">
                                            <div className="form-group mb-3"> 
                                                <label className="fw-medium">Price freetext</label>
                                                <Rte 
                                                    content={formik.values.price_freetext} 
                                                    setContent={(e) => formik.setFieldValue('price_freetext', e)} 
                                                />
                                            </div>
                                        </div>
                                   
                                    <div class="mb-3">
                                        <div className="form-group mb-3">
                                            <label className="fw-medium">Price breakdown</label>
                                            <Rte 
                                                content={formik.values.price_breakdown} 
                                                setContent={(e) => formik.setFieldValue('price_breakdown', e)} 
                                            />
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <FormikInput 
                                            name="comment"
                                            label="Comment (internal)"
                                            type="textarea"
                                            formik={formik}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="shadow-sm px-4 py-3 rounded mb-4">
                                        <div className="mb-3">
                                            <h6>Customer</h6>
                                            <span className="d-block">{data.customer?.fullname}</span>
                                            <span className="d-block">{data.customer?.country}</span>
                                            <span className="d-block">{data.customer?.booking_count > 0 ? data.customer?.booking_count : 'No'} bookings</span>
                                        </div>
                                        <div className="">
                                            <h6>Contact info</h6>
                                            <span className="d-block"><a href={`tel:${data.customer?.phone}`}>{data.customer?.phone}</a></span>
                                            <span className="d-block"><a href={`mailto:${data.customer?.email}`}>{data.customer?.email}</a></span>
                                        </div>
                                    </div>
                                    <div className="shadow-sm px-4 py-3 rounded mb-4">
                                        <div className="">
                                            <h6>Conversion summary</h6>
                                            <span className="d-block">This is their {data.customer?.assignment_count}{getOrdinal(data.customer?.assignment_count)} inquiry</span>
                                            <span className="d-block">Last seen {data.customer?.lastlogin ? ago(data.customer?.lastlogin) : 'never'}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="expenses mb-4">
                                <div className="form-group">
                                    <label className="fw-medium">Expenses</label>
                                </div>
                                {
                                    formik.values.expenses && formik.values.expenses.length ? formik.values.expenses.map((expense, key) => <Expense key={key} index={key} formik={formik} {...expense} />) : null
                                }
                                <div className="row">
                                    <div className="col"><button className="btn btn-sm btn-grey" type="button" onClick={() => addExpense()}>Add expense</button></div>
                                    <div className="col-auto">
                                        <div className="form-group">
                                            <Input 
                                                label="Profit"
                                                prefix={"SEK"}
                                                name="profit"
                                                disabled
                                                value={profit}
                                                type="number"
                                            />
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="persons mb-4">
                            {
                                formik.values.persons && formik.values.persons.length ? formik.values.persons.map((person, key) => <Person key={key} index={key} {...person} formik={formik} />) : null
                            }
                            <button className="btn btn-sm btn-grey" type="button" onClick={() => addPerson()}>Add person</button>
                            </div>
                            <div className="form-group">
                                {/**["sr_occasion_none", "sr_occasion_wedding", "sr_occasion_birthday", "sr_occasion_anniversary", "sr_occasion_other"] */}
                                <FormikInput 
                                    name="special_occation"
                                    label="Special occation"
                                    type="select"
                                    formik={formik}
                                    options={
                                        [
                                            {label: 'None', value: 'sr_occasion_none'},
                                            {label: 'Wedding', value:'sr_occasion_none'},
                                            {label: 'Birthday', value:'sr_occasion_birthday'},
                                            {label: 'Anniversary', value:'sr_occasion_anniversary'},
                                            {label: 'Other', value:'sr_occasion_other'},
                                        ]
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <FormikInput
                                    name="other"
                                    label="Other"
                                    formik={formik}
                                    type="textarea"
                                />
                            </div>
                            <div className="form-group mb-5">
                                <label className="fw-medium">Tickets</label>
                                <FileUpload 
                                    label="Upload tickets / documents"
                                    files={formik.values.attachments}
                                    setFiles={(e) => formik.setFieldValue('attachments', e)}
                                />
                            </div>

                            <div className="form-group mb-5">
                                <h5 className="fw-medium mb-3">Flight information</h5>
                                <h6>Departure</h6>
                                <FormikInput 
                                    name="departure"
                                    formik={formik}
                                    type="date"
                                    disabled
                                />
                                <div className="mb-4">
                                    <Rte 
                                        content={formik.values.flight_information_from} 
                                        setContent={(e) => formik.setFieldValue('flight_information_from', e)} 
                                    />
                                </div>
                                <h6>Arrival</h6>
                                <FormikInput 
                                    name="arrival"
                                    formik={formik}
                                    type="date"
                                />
                                <div className="mb-4">
                                    <Rte content={formik.values.flight_information_to} setContent={(e) => formik.setFieldValue('flight_information_to', e)} />
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="arrivalDayAfter" checked={formik.values.arrivalDayAfter} onChange={(e) => formik.setFieldValue('arrivalDayAfter', e.target.checked ? 1 : 0)} />
                                    <label className="form-check-label fs-20" for="arrivalDayAfter"><h6>At arrival day after?</h6></label>
                                </div>
                            </div>

                            
                        </form>

                        <div className=" mb-4">
                                        <div className="">
                                            <h6>Messages</h6>
                                            {data ? 
                                                <InlineChat 
                                                    id={data.id}
                                                    nr={data.nr}
                                                    type="Booking"
                                                    assignment={data.assignment_id}
                                                />
                                            
                                            : null}
                                        </div>
                                    </div>
                            <div className="form-group mb-5">
                                <div className="row">
                                    <div className="col">
                                        <label className="fw-medium">Booking preview</label>
                                    </div>
                                    <div className="col-auto">
                                        <a href={data.offer_resource_manager_url} target="_blank">Edit</a>
                                    </div>
                                </div>
                                <div className="d-block position-relative" style={{height: 400, border: '1px solid #9f9f9f', background: '#f5f6fa'}}>
                                    <div className="row gx-2" style={{position: 'absolute', top: 20, right: 20}}>
                                        <div className="col-auto"><button className="btn btn-sm btn-grey"  onClick={() => refreshIframe()}>Refresh</button></div>
                                        <div className="col-auto"><a className="btn btn-sm btn-grey" href={`${data.booking_resource_url}&secret=D78xa7sqPw`} target="_blank">View</a></div>
                                    </div>
                                    <iframe key={iframeKey} ref={iframeRef} src={`${data.booking_resource_url}&secret=D78xa7sqPw&${iframeKey}`} style={{height: '800px', width: '100%'}}></iframe>
                                </div>
                            </div>
                    </div>
                </div>
                {showPdf ? 
                <div className="col-auto">
                    <div style={{width: pdfWidth}} className="page bg-white px-md-5 px-3 py-3 mb-4" ref={pdfRef}>
                        <BookingSummary formik={formik} />
                    </div>
                </div>
                : null}
            </div>
        </div>
    )
}

function Person (props) {
    
    const {
        index
    } = props

    const invoiceTemplate = {
        number: null,
        description: '',
        amount: '',
        link: '',
        deadline: '',
        remainingAmount: null,
        paidAmount: null
    }
    const [open, setOpen] = useState(index === 0)

    const [firstName, setFirstName] = useState(props.first_name)
    const [lastName, setLastName] = useState(props.last_name)
    const [dob, setDob] = useState(props.dob)
    const [phone, setPhone] = useState(props.phone)
    const [email, setEmail] = useState(props.email)
    const [allergies, setAllergies] = useState(props.allergies)
    const [invoice, setInvoice] = useState(props.recipientofInvoice === "false" ? false : true)
    const [streetAddress, setStreetAddress] = useState(props.streetAddress)
    const [postalCode, setPostalCode] = useState(props.postalCode)
    const [city, setCity] = useState(props.city)
    const [invoices, setInvoices] = useState(props.invoices ?? [])

    useEffect(() => {
        onPersonChange()
    }, [firstName, lastName, dob, phone, email, allergies, invoice, streetAddress, postalCode, city, invoices])

    const addInvoice = () => {
        setInvoices([...invoices, invoiceTemplate])
    }


    const removePerson = () => {
        const newPersons = props.formik.values.persons
        props.formik.setFieldValue('persons', newPersons.filter((t, k) => k !== index))

    }

    const onPersonChange = () => {
        
        const newPersons = props.formik.values.persons
        const newPerson = newPersons[index]
        newPerson.first_name = firstName
        newPerson.last_name = lastName
        newPerson.dob = dob
        newPerson.phone = phone
        newPerson.email = email
        newPerson.allergies = allergies
        newPerson.recipientofInvoice = invoice
        newPerson.streetAddress = streetAddress
        newPerson.postalCode = postalCode
        newPerson.city = city
        newPerson.invoices = invoices
        newPersons[index] = newPerson
        props.formik.setFieldValue('persons', newPersons)
    }

    return (
        <div className="px-5 py-4 mb-3 border">
            <div className="row">
                <div className="col">Person {index + 1}</div>
                <div className="col-auto"><button className="btn btn-link text-dark" type="button" onClick={() => setOpen(!open)}><FontAwesomeIcon flip={open ? "vertical" : ''} icon={['far', 'chevron-down']} /></button></div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <Input 
                        label="First name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </div>
                <div className="col-md-4">
                    <Input 
                        label="Last name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </div>
                <div className="col-md-4">
                    <Input 
                        label="Date of birth"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                        type="date"
                    />
                </div>
            </div>
            <div style={{display: open ? 'block' : 'none'}}>
                <div className="row">
                    <div className="col-md-4">
                        <Input 
                            label="Phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            type="number"
                        />
                    </div>
                    <div className="col-md-8">
                        <Input 
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Input 
                            label="Allergies"
                            value={allergies}
                            onChange={(e) => setAllergies(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-auto">
                    <div className="mb-3 mt-2">
                        <CheckBox 
                            label="Recipient of invoice"
                            value={invoice}
                            onChange={(e) => setInvoice(e)}
                        />
                    </div>
                    </div>
                    <div className="col-auto">
                        <small>{ props.invoice_for ? `(${props.invoice_for.join(', ')} chosen)` : null }</small>
                    </div>
                </div>
                <div style={{display: invoice ? 'block' : 'none'}} className="mb-3">
                    <div className="row">
                        <div className="col-md-6">
                            <Input 
                                label="Street address"
                                value={streetAddress}
                                onChange={(e) => setStreetAddress(e.target.value)}
                            />
                        </div>
                        <div className="col-md">
                            <Input 
                                label="Postal code"
                                value={postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                                type="number"
                            />
                        </div>
                        <div className="col-md-4">
                            <Input 
                                label="City"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                type="text"
                            />
                        </div>
                    </div>
                    {
                        invoices && invoices.length ? invoices.map((item, key) => <Invoice key={key} index={key} {...item} formik={props.formik} invoices={invoices} setInvoices={setInvoices} />) : null
                    }
                    <button className="btn btn-sm btn-grey" type="button" onClick={() => addInvoice()}>Add invoice</button>
                </div>
                <button type="button" className="btn btn-sm btn-link" onClick={() => removePerson()}>Remove person</button>
            </div>
        </div>
    )
}

function Invoice (props){
    const {apiCall} = useContext(AuthContext)
    const { data } = useContext(Context)
    const { index, invoices, setInvoices } = props

    const [number, setNumber] = useState(null)
    const [description, setDescription] = useState(null)
    const [amount, setAmount] = useState(null)
    const [link, setLink] = useState(null)
    const [deadline, setDeadline] = useState(null)
    const [remainingAmount, setRemainingAmount] = useState(null)
    const [paidAmount, setPaidAmount] = useState(null)
    const [file, setFile] = useState(null)

    

    useEffect(() => {
        setNumber(props.number)
        setDescription(props.description)
        setAmount(props.amount)
        setLink(props.link)
        setFile(props.file)
        setDeadline(props.deadline)
        setPaidAmount(props.paidAmount)
        setRemainingAmount(props.remainingAmount)
    }, [invoices])

    useEffect(() => {
        onChange()
    }, [description, amount, link, deadline, number, file, paidAmount, remainingAmount])

    const removeInvoice = () => {
        
        const newInvoices = invoices.filter((t, k) => k !== index);
       
        setInvoices(newInvoices);
    }

    const onChange = () => {
 
        const newInvoices = invoices
        newInvoices[index] = {
            number: number,
            description: description,
            amount: amount,
            link: link,
            file: file,
            deadline: deadline,
            remainingAmount: remainingAmount,
            paidAmount: paidAmount
        };
        setInvoices(newInvoices);
    }

    const fetchInvoice = async () => {
        if(!number){
            status({status: 2, message: 'You need to enter an invoice number'})
            return
        }
        const d = await apiCall({
            action: 'getAllInvoiceDetails',
            invoice: number,
            booking: data.id
        })

        status(d)

        if(d.status === 1){
            setAmount(d.data.amount)
            setDescription(d.data.description)
            setDeadline(d.data.deadline)
            setFile(d.data.file)
            setRemainingAmount(d.data.remainingAmount)
            setPaidAmount(d.data.paidAmount)
            onChange()
        }
        
    
    }

    const drawPaymentStatus = () => {
        if(paidAmount === null || remainingAmount === null) return ''
        if(parseInt(remainingAmount) === 0) return <div className="col-auto"><small className='bg-success btn btn-sm' style={{'--bs-bg-opacity': .7}}>Paid</small></div>
        if(parseInt(paidAmount) === 0) return <div className="col-auto"><small className='bg-danger btn btn-sm' style={{'--bs-bg-opacity': .7}}>Unpaid</small></div>
        if(parseInt(paidAmount) !== parseInt(amount)) return <><div className="col-auto">Payment due: {channelCurrency(data.channel)} {new Intl.NumberFormat('nb-NO').format(remainingAmount)}</div><div className="col-auto"><small className='bg-danger btn btn-sm' style={{'--bs-bg-opacity': .7}}>Partially paid</small></div></>
    }

    const getPaymentStatus = async () => {
        if(!number){
            status({status: 2, message: 'You need to enter an invoice number'})
            return
        }
        const d = await apiCall({
            action: 'getInvoicePaymentDetails',
            invoice: number,
            booking: data.id
        })

        status(d)

        if(d.status === 1){
            setRemainingAmount(d.data.remainingAmount)
            setPaidAmount(d.data.paidAmount)
            onChange()
            props.formik.handleSubmit()
        }
        
    }

    return (
        <div class="border p-3 mb-3">
            <div className="row mb-3">
                <div className="col">
                    <h6>Invoice</h6>
                </div>
                <div className="col-auto">
                    <div className="row gx-2 justify-content-center">
                        {drawPaymentStatus()}
                        <div className="col-auto">
                            <ContextMenu
                                buttonEl={<button type="button" class="btn btn-sm btn-grey">
                                    <div className="row gx-2">
                                        <div className="col-auto">Actions</div>
                                        <div className="col-auto"><FontAwesomeIcon icon={['far', 'chevron-down']} size="xs" /></div>
                                    </div>
                                </button>}
                            >
                                <ContextMenuItem
                                    label="Fetch invoice"
                                    onClick={() => fetchInvoice()}
                                />
                                <ContextMenuItem
                                    label="Get payment status"
                                    onClick={() => getPaymentStatus()}
                                />
                                <ContextMenuItem
                                    label="Remove invoice"
                                    onClick={() => removeInvoice()}
                                />
                                
                            </ContextMenu>
                        </div>
                    </div>
                
                </div>
            </div>
            <div className="row">
                <div className="col-2">
                    <Input 
                        label="Number"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        type="number"
                    />
                </div>
                <div className="col">
                    <Input 
                        label="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
                <div className="col-4">
                    <Input 
                        label="Amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        prefix={channelCurrency(data.channel)}
                        type="number"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <InvoiceFileInput 
                        file={file}
                        setFile={(e) => setFile(e)}
                    />
                </div>
                <div className="col-3">
                    <Input 
                        label="Deadline"
                        value={deadline}
                        onChange={(e) => setDeadline(e.target.value)}
                        type="date"
                    />
                </div>
                
            </div>
        </div>
    )
}

function Expense (props) {
    const { apiCall } = useContext(AuthContext)
    const { suppliers } = useContext(Context)

    const [supplier, setSupplier] = useState(props.supplier_id)
    const [from_currency, setFromCurrency] = useState(props.from_currency)
    const [from_price, setFromPrice] = useState(props.from_price)
    const [price, setPrice] = useState(props.price)
    const [expenseStatus, setExpenseStatus] = useState(props.status)

    const random = Math.floor(Math.random() * 999)


    useEffect(() => {
        onExpenseChange()
    }, [supplier, from_currency, from_price, price, expenseStatus])

    const deleteExpense = async () => {
        const d = await apiCall({action: 'deleteExpense', id: props.id})
        status(d)
        if(d.status === 1){
            const newExpenses = props.formik.values.expenses.filter((t, i) => i !== props.index)
            props.formik.setFieldValue('expenses', newExpenses)
            props.formik.submitForm()
        }
    }

    const onExpenseChange = () => {
        const newExpenses = props.formik.values.expenses
        newExpenses[props.index] = {
            id: props.id,
            supplier_id: supplier,
            from_currency: from_currency,
            from_price: from_price,
            price: price,
            status: expenseStatus
        }
        props.formik.setFieldValue('expenses', newExpenses)
    }

    
    return (
        <div className="position-relative expense-item">
            <div className="delete-expense">
                <button onClick={() => deleteExpense()} className="btn btn-danger pe-5"><FontAwesomeIcon icon={['fal', 'trash-alt']} /></button>
            </div>
            <div className="row">
                <div className="col">
                    <DropDown
                        value={supplier}
                        placeholder="Select a supplier"
                        options={
                            suppliers.map((sup) =>  {
                                return {label: sup.name, value: sup.id}
                            })
                        }
                        onChange={(value) => {
                            
                            setSupplier(value)
                        }}
                    />
                </div>
                <div className="col-3">
                    <Input 
                        value={from_price}
                        prefix={
                            <input  type="text" name={'from_price'} value={from_currency} onChange={(e) => setFromCurrency(e.target.value)} placeholder={'USD'} className="small-input" />
                        }
                        onChange={(e) => setFromPrice(e.target.value)}
                    />
                </div>
                <div className="col-3">
                    <Input 
                        value={price}
                        prefix={'SEK'}
                        onChange={(e) => setPrice(e.target.value)}
                    />
                </div>
                <div className="col-auto">
                    <input id={`checkPaid${random}`} type="checkbox" className="big-check"  checked={expenseStatus === 'paid' ? 'checked' : ''} onChange={(e) => setExpenseStatus(e.target.checked ? 'paid' : 'unpaid')} />
                    <label htmlFor={`checkPaid${random}`}><span><FontAwesomeIcon icon={['far', 'check']} className="text-success" size="lg" /></span></label>
                </div>
            </div>
        </div>
    )
}

function BookingSummary ({ formik }) {
    const { data } = useContext(Context)
    const [height, setHeight] = useState(600)
    const ref = useRef()
    const styles = StyleSheet.create({
        body: {
          paddingTop: 35,
          paddingBottom: 65,
          paddingHorizontal: 35,
          fontSize: 12
        },
        title: {
          fontSize: 24,
          fontWeight: 600
        },
        page: {
            flexDirection: 'row',
            backgroundColor: '#FFF'
          },
        section: {
            marginBottom: 20
        },
        label: {
            fontWeight: 600,
            color: 'rgba(0,0,0,.8)',
            fontSize: 10
        },
        noMargRow:{
            display: 'flex',
            flexDirection:'row'
        },
        row: {
            padding: 10,
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 10
        },
        personRow: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 10,
            borderBottom: '1px dashed black',
            paddingBottom: 10
        },
        col: {
            flex: '1 0'
        }
    });

    const PDFContent = () => {
        const Person = (props) => {
            const { recipientofInvoice, first_name, last_name, dob, phone, email, allergies, invoices, streetAddress, postalCode, city} = props
            
            return (
                <View wrap={false} style={{border: '1px dashed black', padding: 5, margin: '10px 0'}}>
                    <View style={styles.personRow}>
                        <View style={styles.col}>
                            <Text style={styles.label}>Name</Text>
                            <Text>{first_name} {last_name}</Text>
                        </View>
                        <View style={styles.col}>
                            <Text style={styles.label}>Date of birth</Text>
                            <Text>{dob}</Text>
                        </View>
                        
                    </View>
                    <View style={styles.personRow}>
                        <View style={styles.col}>
                            <Text style={styles.label}>Email address</Text>
                            <Text>{email}</Text>
                        </View>
                        <View style={styles.col}>
                            <Text style={styles.label}>Phone</Text>
                            <Text>{phone}</Text>
                        </View>
                    </View>
                    {recipientofInvoice ? 
                    <View style={styles.personRow}>
                        <View style={styles.col}>
                            <Text style={styles.label}>Address</Text>
                            <Text>{streetAddress}</Text>
                        </View>
                        <View style={styles.col}>
                            <Text style={styles.label}>Post code</Text>
                            <Text>{postalCode}</Text>
                        </View>
                        <View style={styles.col}>
                            <Text style={styles.label}>City</Text>
                            <Text>{city}</Text>
                        </View>
                        
                    </View>
                    : null}
                    <View style={styles.noMargRow}>
                        <View style={styles.col}>
                            <Text style={styles.label}>Allergies</Text>
                            <Text>{allergies}</Text>
                        </View>
                        <View style={styles.col}>
                            <Text style={styles.label}>Inovices</Text>
                            <Text>{invoices.length}</Text>
                        </View>
                    </View>
                </View>
            )
        }
        return (
            <Document>
                <Page size="A4" style={styles.body}>
                    <View style={styles.section}>
                        <Text style={styles.title}>Booking #{data.nr} </Text>
                        <Text>{formik.values.title}</Text>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col}>
                            <Text style={styles.label}>Departure</Text>
                            <Text>{formik.values.departure}</Text>
                        </View>
                        <View style={styles.col}>
                            <Text style={styles.label}>Total price</Text>
                            <Text>{channelCurrency(data.channel)} {formik.values.price.toLocaleString('nb-NO')}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col}>
                            <Text style={styles.label}>Special occation</Text>
                            <Text>{formik.values.special_occation}</Text>
                        </View>
                        <View style={styles.col}>
                            <Text style={styles.label}>Other</Text>
                            <Text>{formik.values.other}</Text>
                        </View>
                    </View>
                    <View>
                        <Text style={styles.label}>Persons</Text>
                        {formik.values.persons.map((i, k) => <Person key={k} {...i} />)}
                    </View>
                </Page>
            </Document>
        )
    }

    useEffect(() => {
        if(ref?.current){
            setPdfHeight()
        }
    }, [ref])

    

    const setPdfHeight = () => {
        if(ref?.current){
            const h = ref.current.clientWidth * 1.14
            setHeight(h)
        }
    }
    window.addEventListener('resize', setPdfHeight)

    return (
        <div className="h-100">
            <div className="row justify-content-between">
                <div className="col-auto">
                    <h3>Booking summary</h3>
                </div>
                <div className="col-auto">
                <PDFDownloadLink document={<PDFContent />} fileName={`booking-summary-#${data.nr}.pdf`}>
                {({ blob, url, loading, error }) =>
                    loading ? <button disabled type="button" className="btn btn-sm btn-grey">Loading</button> : <button type="button" className="btn btn-sm btn-grey">Download</button>
                }
                </PDFDownloadLink>
                </div>
            </div>
            
            
            <PDFViewer style={{width: '100%', height: window.innerHeight, backgroundColor: 'white'}}>
                <PDFContent />
            </PDFViewer>
            
        </div>
    )
}

function Chat({ data }){

    
    const { apiCall, user } = useContext(AuthContext)

    const [sortedMessages, setSortedMessages] = useState(null)
    const [messages, setMessages] = useState(null)
    const [message, setMessage] = useState('')
    const [users, setUsers] = useState([user])
    const ref = useRef()

    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        loadConversation()
    }, [])

    useEffect(() => {
        if(messages){
            setUsers([...messages.users, user])
            const sorted = messages.messages.sort(function(a,b){
                return new Date(a.createdon) - new Date(b.createdon);
            })
            
            setSortedMessages(sorted)
        }

    }, [messages])

    const loadConversation = async () => {
        if(data && data.assignment_id){
            const d = await apiCall({action: 'getConversationMessages', id: data.assignment_id})
            if(d.status === 1){
                setMessages(d.data)
            }else{
                status(d)
            }
        }
    }

    useEffect(() => {
        if(ref && ref.current) ref.current.scrollTop = ref.current.scrollHeight
    }, [sortedMessages])

   useEffect(() => {
        if(ref && ref.current) ref.current.scrollTop = ref.current.scrollHeight
    }, [ref])

    const newMessage = async (e) => {
        e.preventDefault()
        
        if(!message.length) return
        setDisabled(true)
        const m = {
            id: sortedMessages.length + 1,
            from: user.id,
            message: message,
            state: {
                id: data.id,
                no: data.nr,
                type: 'Booking'
            },
            createdon: moment().format('YYYY-MM-DD HH:mm')
        }
        const d = await apiCall({action: 'newMessage', assignment: data.assignment_id, m})
        
        if(d.status === 1){
            setSortedMessages([
                ...sortedMessages,
                m
            ])
            setMessage('')
        }else{
            status(d)
        }

        setDisabled(false)
    }

    const MessageItem = (props) => {
        const { id, from, message, createdon, state, files } = props
        const messageUser = users.filter((u) => u.id === parseInt(from))[0]
        const me = messageUser?.id === user?.id
        const getUrl = () => {
            switch(state.type){
                case 'Booking': return 'bookings'
                case 'Offer': return 'offers'
                case 'Inquiry': return 'inquiries'
                default: return ''
            }
        }

        const deleteMessage = async () => {
            if(await confirm('Delete message', 'Are you sure you wan\'t to delete this message ?', {label: 'Yes, delete', type: 'danger'})){
                const d = await apiCall({action: 'deleteAssignmentMessage', id: id, assignment: data.assignment_id})
                status(d)
                if(d.status === 1){
                    setSortedMessages(sortedMessages.filter((m) => m.id !== id))
                }
                
            }
        }
        const { downloadAttachment } = useContext(AuthContext)
        
        const downloadFile = async ({ name, path, type}) => {
            await downloadAttachment({
                name: name,
                path: path,
                type: type
            })
        }

        const FileItem = ({ name, type, size, path }) => {
            return (
                
                <small className="pointer text-primary d-block mt-1" onClick={() => downloadFile({name, path, type})}>
                    <FontAwesomeIcon className="me-2" icon={['fas', 'paperclip']} /> <u style={{textDecoration: 'none'}}>{name}</u>
                </small>
                
            )
        }
        return (
            <div className={`message-container ${me ? 'message-from-me' : ''}`}>
                <div className="message-bubble-wrapper">
                    <small className="hint-text d-block">{moment(createdon).format('MMMM D, YYYY [at] HH:mm')} <FontAwesomeIcon onClick={() => deleteMessage()} icon={['far', 'trash-alt']} title="Delete this message" className="ms-2 text-danger pointer" /></small>
                    {state ? <small><Link to={`/${getUrl()}/${state.id}`}>#{state.no}</Link> {state.type}</small> : null}
                    <div className="message-bubble">
                    <pre dangerouslySetInnerHTML={{__html: message.trim()}}></pre>
                        {
                            files && files.length ? files.map((v,k) => <FileItem key={k} {...v} />) : null
                        }
                    </div>
                    <div className="message-user">
                        <div className="row gx-2 align-items-center">
                            <div className="col-auto">
                                <div className="profile-image">
                                    {messageUser ? drawProfileBubble(messageUser) : 'XX'}
                                </div>
                            </div>
                            <div className="col">
                                <span className="d-block"><a href="#">{messageUser ? messageUser?.fullname : 'Unknown / deleted'}</a></span>
                                <span className="d-block"><a href="#">{messageUser?.phone}</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if(!sortedMessages) {
        return (
            <div className="d-flex justify-content-center align-items-center flex-column h-100">
                <div className="text-center">
                    <FontAwesomeIcon icon={['fad', 'spinner-third']} spin size="10x" className="text-success mb-3" />
                    <h5>Loading conversation..</h5>
                </div>
            </div>
        )
    }

    
    return (
        <>
            <div className="overscroll d-flex flex-column flex-grow-1 flex-basis-0 p-0" style={{height: 500}} ref={ref}>
                {
                    sortedMessages.map((item, key) => <MessageItem key={key} {...item} />)
                }
            </div>
            <form onSubmit={newMessage}>
                <div className="form-group mt-2">
                    <div className="row align-items-center">
                        <div className="col mb-2">
                            <textarea type="text" placeholder="Write a message" className="form-control" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-primary btn-sm w-100" type="submit" disabled={disabled} >
                                <div className="row gx-2 justify-content-center">
                                    <div className="col-auto">Send</div>
                                    {disabled ? <div className="col-auto"><FontAwesomeIcon icon={['far', 'spinner-third']} spin /></div> : null}
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )

}
