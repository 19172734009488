import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function CheckBox({ label, value, onChange, description, classes, name }){

    const random = 'chk'+Math.floor(Math.random() * 10000) + 1
    return (
        <div className={"checkbox" + (classes ? ' ' + classes : '') }>
            <input name={name} type="checkbox" checked={value ? 'checked' : ''} id={'check-' + random} onChange={(e) => onChange(e.target.checked)} />
            <label htmlFor={'check-' + random}>
                <span>
                    <span>
                        
                    </span>
                </span>
                {label}
            </label>
            {
                description ? <p>{description}</p> : null
            }
            
        </div>
        
    )
}