import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContextMenu, { ContextMenuItem } from "../components/ContextMenu";
import { useContext, useEffect, useState } from "react";
import PageLoading from "../components/PageLoading";
import { channelCurrency, channelToCountry, channelToLanguage, drawStatus, formatDate, status, statusBubble } from "../components/Functions";
import moment from "moment";
import Modal from "../components/Modal";
import { AuthContext } from "../providers/AuthProvider";
import { useParams } from "react-router";
import { Formik, Form } from "formik";
import FormikInput from "../components/FormikInput";
import { Link } from "react-router-dom";
import { confirm } from "../components/Confirmation";

export default function Customer(){

    const { apiCall } = useContext(AuthContext)
    const params = useParams()

    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [editMode, setEditMode] = useState(false)

    useEffect(() => {
        getCustomerDetails()
    }, [])

    const getCustomerDetails = async () => {
        const d = await apiCall({action: 'getCustomerDetails', id: params.id})
        console.log(d)
        if(d.status === 1){
            setData(d.data)
        }else{
            setError(d)
        }
    }

    const editCustomer = async (values) => {
        const d = await apiCall({action: 'editCustomer', id: params.id, ...values})
        console.log(d)
        status(d)
        if(d.status === 1){
            setEditMode(false)
            getCustomerDetails()
        }
    }

    const resetPassword = async (values) => {
        if( await confirm('Reset password?', 'By reseting this customers password, their old will not work anymore. They will recieve an email with instructions to set a new password.', {label: 'Yes, reset and send', type: 'warning'})){
            const d = await apiCall({action: 'resetCustomerPassword', id: params.id })
            status(d)
        }
    }

    const InquiryItem = ({id, nr, trip, createdon, price, channel, status}) => {
        return (
            <div  className="mb-4">
                <Link to={`../inquiries/${id}`}>
                    <div className="row">
                        <div className="col-auto">
                            #{id}
                        </div>
                        <div className="col">
                            <span className="fw-medium">{trip.title}</span>
                        </div>
                        <div className="col-auto">
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-auto">
                            {formatDate(createdon)}
                        </div>
                        <div className="col">
                            <span className="fw-medium">{channel}</span>
                        </div>
                        <div className="col-auto">
                            {statusBubble(status)}
                        </div>
                    </div>
                </Link>
            </div>
        )
    }

    const OfferItem = ({inquiry, id, nr, offer, title, createdon, price, channel, status, seller}) => {
        return (
            <div className="mb-4">
                <Link to={`../offers/${id}`}>
                <div className="row">
                    <div className="col-auto">
                        #{nr}
                    </div>
                    <div className="col">
                        <span className="fw-medium">{title}</span>
                    </div>
                    <div className="col-auto">
                        {channelCurrency(channel)} {new Intl.NumberFormat('nb-NO').format(price)}
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto">
                        {formatDate(createdon)}
                    </div>
                    <div className="col-auto">
                        <span className="fw-medium">{channel}</span>
                    </div>
                    <div className="col">
                        <span className="fw-medium">{seller.fullname}</span>
                    </div>
                    <div className="col-auto">
                        {statusBubble(status)}
                    </div>
                </div>
                </Link>
            </div>
        )
    }

    const BookingItem = ({inquiry, id, nr, offer, title, createdon, price, channel, status, payment_status, seller}) => {
        return (
            <div className="mb-4">
                <Link to={`../bookings/${id}`}>
                <div className="row">
                    <div className="col-auto">
                        #{nr}
                    </div>
                    <div className="col">
                        <span className="fw-medium">{title}</span>
                    </div>
                    <div className="col-auto">
                        {channel === 'NO' ? 'NOK' : 'SEK'} {new Intl.NumberFormat('nb-NO').format(price)}
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto">
                        {formatDate(createdon)}
                    </div>
                    <div className="col-auto">
                        <span className="fw-medium">{channel}</span>
                    </div>
                    <div className="col">
                        <span className="fw-medium">{seller.fullname}</span>
                    </div>
                    <div className="col-auto">
                        {statusBubble(payment_status)}
                    </div>
                    <div className="col-auto">
                        {statusBubble(status)}
                    </div>
                </div>
                </Link>
            </div>
        )
    }

    return !data && !error? <PageLoading /> : error ? <p>Error</p> : (
        <div className="bg-light-grey h-100">
            <div className="page bg-white px-md-5 px-3 py-3">
                <div className="row gx-0 mb-5">
                    <div className="col">
                        <h3>{data.first_name} {data.last_name}</h3>
                        <div className="row gx-2 align-items-center">
                            <div className="col-auto">{channelToCountry(data.channel)}</div>
                            <div className="col-auto"><FontAwesomeIcon icon={['fas', 'circle']} size="xs" style={{transform: 'scale(.5)'}} /></div>
                            <div className="col-auto">Customer since {moment(data.createdon).format('YYYY')}</div>
                            
                        </div>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-sm btn-warning" onClick={() => resetPassword()}>Reset password</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="shadow-sm px-4 py-3 rounded mb-4">
                            <div className="row">
                                <div className="col-md-5">
                                    <small className="hint-text d-block mb-3">Amount spent</small>
                                    {channelCurrency(data.channel)} {new Intl.NumberFormat('nb-NO').format(data.amount_spent)}
                                </div>
                                <div className="col">
                                <small className="hint-text d-block mb-3">Bookings</small>
                                {data.bookings.length}
                                </div>
                            </div>
                        </div>
                        { data.inquiries &&  data.inquiries.length ? 
                        <div className="shadow-sm px-4 py-3 rounded mb-4">
                            <small className="hint-text d-block mb-3">Inquiries</small>
                            {
                                data.inquiries.map((item, key) => <InquiryItem key={key} {...item} />)
                            }
                        </div>
                        : null }
                        { data.offers &&  data.offers.length ? 
                        <div className="shadow-sm px-4 py-3 rounded mb-4">
                            <small className="hint-text d-block mb-3">Offers</small>
                            {
                                data.offers.map((item, key) => <OfferItem key={key} {...item} />)
                            }
                        </div>
                        : null }
                        {data.bookings && data.bookings.length ? 
                        <div className="shadow-sm px-4 py-3 rounded mb-4">
                            <small className="hint-text d-block mb-3">Bookings</small>
                            {
                                data.bookings.map((item, key) => <BookingItem key={key} {...item} />)
                            }
                        </div>
                        : null }
                    </div>
                    <div className="col-md-4">
                        
                        <div className="shadow-sm rounded mb-4">
                            <div className="row justify-content-end">
                                <div className="col-auto">
                                    <button className="btn btn-lin btn-sm" onClick={() => setEditMode(true)}><FontAwesomeIcon icon={['far', 'pencil']} /></button>
                                </div>
                            </div>
                            <div className="px-4 py-3">
                                <div className="mb-4">
                                    <h6>Contact info</h6>
                                    <span className="d-block mb-1"><a href={`tel:${data.phone}`}>{data.phone}</a></span>
                                    <span className="d-block mb-1"><a href={`mailto:${data.email}`}>{data.email}</a></span>
                                    <span className="d-block">Will recieve notifications in {channelToLanguage(data.channel)}</span>
                                </div>
                                <div>
                                    <h6>Address</h6>
                                    <span className="d-block mb-1">{data.fullname}</span>
                                    <span className="d-block mb-1">{data.address.streetAddress}</span>
                                    <span className="d-block mb-1">{data.address.postCode} {data.address.city}</span>
                                    <span className="d-block">{channelToCountry(data.channel)}</span>
                                </div>
                            </div>
                        </div>
                        {/**
                        <div className="shadow-sm px-4 py-3 rounded mb-4">
                            <div className="">
                                <h6>Messages</h6>
                                <span className="d-block"><a href="#">1 unread message</a></span>
                            </div>
                        </div>
                         */}
                    </div>
                </div>
            </div>
            <Modal open={editMode} setOpen={setEditMode}>
                <Formik
                    initialValues={{
                        id: data ? data.id : 0,
                        email: data ? data.email : '',
                        country: data ? data.country : '',
                        channel: data ? data.channel : '',
                        first_name: data ? data.first_name : '',
                        last_name: data ? data.last_name : '',
                        phone: data ? data.phone : '',
                        address: data ? data.address?.streetAddress : '',
                        zip: data ? data.address?.postCode : '',
                        city: data ? data.address?.city : '',
                    }}
                    onSubmit={(values) => {
                        editCustomer(values)
                    }}
                >
                    <Form>
                        <div className="">
                            <h3>Edit customer</h3>
                            <div className="form-group mb-3">
                                <FormikInput
                                    name="email"
                                    label="Email address"
                                    placeholder="Email address"
                                    type="email"
                                />
                            </div>
                            <FormikInput 
                                name="country"
                                label="Country"
                                type="select"
                                options={
                                    [
                                        {label: 'Sweden', value:'SE'},
                                        {label: 'Norway', value:'NO'},
                                        {label: 'Denmark', value:'DK'}
                                    ]
                                }
                            />
                            <FormikInput 
                                name="channel"
                                label="Channel"
                                type="select"
                                options={
                                    [
                                        {label: 'Swedish website (SE)', value:'SE'},
                                        {label: 'Norwegian website (NO)', value:'NO'},
                                        {label: 'Danish website (NO)', value:'DK'}
                                    ]
                                }
                            />
                            <div className="row gx-1 mb-3">
                                <div className="col-6">
                                    <FormikInput
                                        name="first_name"
                                        label="First name"
                                    />
                                </div>
                                <div className="col-6">
                                    <FormikInput
                                        name="last_name"
                                        label="Last name"
                                    />
                                </div>
                            </div>
                            <FormikInput
                                name="phone"
                                label="Phone"
                                placeholder="+## ########"
                            />
                            <div className="row">
                                <div className="col">
                                    <FormikInput
                                        name="address"
                                        label="Street address"
                                    />
                                </div>
                                <div className="col-2">
                                    <FormikInput
                                        name="zip"
                                        label="Postal code"
                                    />
                                </div>
                                <div className="col-3">
                                    <FormikInput
                                        name="city"
                                        label="City"
                                    />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary w-100">Save profile</button>
                        </div>
                    </Form>
                </Formik>
            </Modal>
        </div>
    )
}