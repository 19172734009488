import React from "react";
import { confirmable, createConfirmation } from "react-confirm";

const Confirmation = ({
    proceedButton = { label: 'Ok', type: 'primary' },
    text = 'Are you sure?',
    cancelLabel = "Cancel",
    confirmation,
    show,
    proceed,
    enableEscape = true
}) => {
  return (
    <div className="static-modal">
        <div className="static-modal-container">
            <h4>{confirmation}</h4>
            <p>{text}</p>
            <div className="row gx-2 justify-content-between">
                <div className="col-auto">
                    <button className="btn btn-grey" onClick={() => proceed(false)}>{cancelLabel}</button>
                </div>
                <div className="col-auto">
                    <button className={`btn btn-${proceedButton.type}`} onClick={() => proceed(true)}>{proceedButton.label}</button>
                </div>
            </div>
        </div>
    </div>
  );
};


export function confirm(
  confirmation,
  text = "Are you sure?",
  proceedButton = {
    label: 'Ok',
    type: 'primary'
  },
  cancelLabel = "Cancel",
  options = {}
) {
  return createConfirmation(confirmable(Confirmation))({
    confirmation,
    text,
    proceedButton,
    cancelLabel,
    ...options
  });
}
