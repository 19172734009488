import { useContext, useState } from "react";
import { AuthContext } from "../providers/AuthProvider";
import Input from '../components/Input'

export default function Login(){
    const { login } = useContext(AuthContext)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const doLogin = (e) => {
        e.preventDefault()
        login(username, password)
        
    }
    return (
        <div className="d-flex flex-vertical login-wrapper align-items-center justify-content-center w-100" style={{background: 'rgba(31,2,3, .5)'}}>
            <div className="login-container p-4 rounded bg-white shadow">
                <h3 className="mb-4">Safariresor Admin</h3>
                <form onSubmit={doLogin}>
                    <Input 
                        name="username"
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                        placeholder="Username / email"
                    />
                    <Input 
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        value={password}
                        placeholder="Your password"
                    />
                    <button type="submit" className="btn btn-primary w-100 mt-4">Login</button>
                </form>
            </div>
        </div>
    )
}