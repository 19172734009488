import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function PageLoading(){
    return (
        <div className="bg-light-grey h-100">
            <div className="page bg-white px-5 py-3 d-flex flex-column h-100 align-items-center justify-content-center">
                <FontAwesomeIcon icon={['fad', 'spinner-third']} size="5x" className="text-success" spin />
                <p>Loading...</p>
            </div>
        </div>
    )
}