import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContextMenu, { ContextMenuItem } from "../components/ContextMenu";
import { useContext, useEffect, useRef, useState } from "react";
import PageLoading from "../components/PageLoading";
import { ago, drawProfileBubble, drawStatus, formatDate, getOrdinal, status, statusBubble } from "../components/Functions";
import moment from "moment";
import Modal from "../components/Modal";
import { AuthContext } from "../providers/AuthProvider";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import * as yup from 'yup';
import FormikInput from '../components/FormikInput'
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { confirm } from "../components/Confirmation";
import InlineChat from "../components/InlineChat";

export default function Inquiry(){

    const {apiCall} = useContext(AuthContext)

    const params = useParams()
    const navigate = useNavigate()

    const [data, setData] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)

    const [trips, setTrips] = useState(null)
    const [customers, setCustomers] = useState(null)

    useEffect(() => {
        if(data){
            getUnreadMessages()
        }
    }, [data])

    const [unreadMessages, setUnreadMessages] = useState(0)

    useEffect(() => {
        getDetails()
    }, [])

    const getDetails = async () => {
        const d = await apiCall({action: 'getInquiryDetails', id: params.id})
        if(d.status === 1) setData(d.data)
    }

    const deleteInquiry = async () => {
        if(await confirm('Delete inquiry', 'Are you sure you wan\'t to delete this inquiry?', {label: 'Yes, delete', type: 'danger'})){
            const d = await apiCall({action: 'deleteInquiry', id: params.id})
            if(d.status === 1) navigate('/inquiries')
            status(d)
        }
    }

    const getUnreadMessages = async () => {
        const d = await apiCall({
            action: 'getUnreadMessagesBasedOnState', 
            assignment: data.assignment_id,
            state: {
                id: data.id,
                no: data.id,
                type: 'Inquiry'
            }
        })

        setUnreadMessages(d)
    }

    return !data ? <PageLoading /> : (
        <div className="bg-light-grey h-100">
            <div className="page bg-white px-md-5 px-3 py-3">
                <div className="row gx-0 mb-5">
                    <div className="col">
                        <h3>Inquiry #{data.id}</h3>
                        <div className="row gx-3 align-items-center mb-2">
                            <div className="col-auto">{formatDate(data.createdon)}</div>
                            <div className="col-auto">{data.channel}</div>
                        </div>
                        {statusBubble(data.status)}
                    </div>
                    <div className="col-auto">
                        <div className="row gx-2 align-items-center">
                            <div className="col-auto">
                                <button className="btn btn-sm btn-primary" onClick={() => setModalOpen(true)}>Create offer</button>
                            </div>
                            <div className="col-auto">
                                <ContextMenu
                                        buttonEl={<button className="btn btn-sm btn-grey">
                                        <div className="row gx-2">
                                            <div className="col-auto">More actions</div>
                                            <div className="col-auto"><FontAwesomeIcon icon={['far', 'chevron-down']} size="xs" /></div>
                                        </div>
                                    </button>}
                                    >
                                        
                                        <ContextMenuItem 
                                            label="Delete"
                                            onClick={() => deleteInquiry()}
                                            colorClass="bg-danger text-white"
                                        />
                                    </ContextMenu>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="mb-4">
                            <span className="fw-medium d-block">Interests</span>
                            <span className="fw-medium d-block">{data.trip.title}</span>
                        </div>
                        <div className="mb-4">
                            <span className="fw-medium d-block">Number of people</span>
                            <span className="d-block">{data.people ? data.people  : <i>Not provided</i>}</span>
                        </div>
                        <div className="mb-4">
                            <span className="fw-medium d-block">Preffered departure date</span>
                            <span className="d-block">{data.departure ? moment(data.departure).format('dddd D. MMMM YYYY') : <i>Not selected</i>}</span>
                        </div>
                        <div className="mb-4">
                            <span className="fw-medium d-block">Preffered airport</span>
                            <span className="d-block">{data.airport ? data.airport : <i>Not provided</i>}</span>
                        </div>
                        <div className="mb-4">
                            <span className="fw-medium d-block">Message</span>
                            <pre class="inquirymessage" dangerouslySetInnerHTML={{__html: data.message.trim()}}></pre>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="shadow px-4 py-3 rounded mb-4">
                            {
                                data.customer ? 
                                    <>
                                    <div className="mb-3">
                                        <h6>Customer</h6>
                                        <span className="d-block">{data.customer.fullname}</span>
                                        <span className="d-block">{data.customer.country}</span>
                                        <span className="d-block">{data.customer.booking_count > 0 ? data.customer.booking_count : 'No'} bookings</span>
                                    </div>
                                    <div className="">
                                        <h6>Contact info</h6>
                                        <span className="d-block"><a href={`tel:${data.customer.phone}`}>{data.customer.phone}</a></span>
                                        <span className="d-block"><a href={`mailto:${data.customer.email}`}>{data.customer.email}</a></span>
                                    </div>
                                    </>
                                : 
                                <div>
                                    <h6>Customer</h6>
                                    <p className="m-0">No customer details provided</p>
                                </div>
                            }
                        </div>
                        <div className="shadow px-4 py-3 rounded mb-4">
                            <div className="">
                                <h6>Conversion summary</h6>
                                <span className="d-block">This is their {data.customer.assignment_count}{getOrdinal(data.customer.assignment_count)} inquiry</span>
                                <span className="d-block">Last seen {data.customer.lastlogin ? ago(data.customer.lastlogin) : 'never'}</span>
                            </div>
                        </div>
                        <div className="shadow px-4 py-3 rounded mb-4">
                            <div className="">
                                <h6>Messages</h6>
                                {
                                    data ? <InlineChat
                                    id={data.id}
                                    nr={data.nr}
                                    type="Inquiry"
                                    assignment={data.assignment_id}
                                /> : null
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={modalOpen}
                setOpen={setModalOpen}
                containerStyle={{width: 500}}
            >
                <CreateOffer 
                    data={data} 
                    setModalOpen={setModalOpen}
                    trips={trips}
                    setTrips={setTrips}
                    customers={customers}
                    setCustomers={setCustomers}
                />                
            </Modal>
        </div>
    )
}


function CreateOffer({ data, setModalOpen, trips, setTrips, customers, setCustomers }){
    const { apiCall } = useContext(AuthContext)

    const navigate = useNavigate()
    
    useEffect(() => {
        if(!trips) loadTrips()
        if(!customers) loadCustomers()
    }, [])

    const loadTrips = async () => {
        const d = await apiCall({action: 'getTrips'})
        if(d.status === 1){
            const temp = []
            d.data.forEach((t) => {
                temp.push({
                    label: t.pagetitle,
                    value: t.id,
                    selected: data.trip.id === t.id
                })
            })
            setTrips(temp)
        }
    }

    const loadCustomers = async () => {
        const d = await apiCall({action: 'getCustomers'})
        if(d.status === 1){
            const temp = []
            d.data.forEach((c) => {
                temp.push({
                    label: `${c.fullname} (${c.email})`,
                    value: c.id,
                    selected: data.customer.id === c.id
                })
            })
            setCustomers(temp)
        }
    }


    let schema = yup.object().shape({
        customer_id: yup.string().required('First name required'),
        trip_id: yup.string().required('First name required'),
        departure: yup.string().required('Phone required'),
    });

    const formik = useFormik({
        initialValues: {
            customer_id: data?.customer?.id,
            trip_id: data?.trip?.id,
            adults: 0,
            youth: 0,
            children: 0,
            departure: data?.departure
        },
        validationSchema: schema,
        onSubmit: (values) => {  
            newOffer(values)
        }
    });

    const newOffer = async (v) => {
        const d = await apiCall({
            action: 'newOffer',
            inquiry_id: data.id,
            ...v
        })

        if(d.status === 1){
            toast.success(d.message);
            navigate(`/offers/${d.offer?.id}`)
        }else{
            toast.error(d.message)
        }
    }
    return (
        <>
        <div className="row">
            <div className="col">
                <h3>Create Offer</h3>
            </div>
            <div className="col-auto">
                <button className="btn btn-link text-dark" onClick={() => setModalOpen(false)}><FontAwesomeIcon icon={['far', 'times']} size="" /></button>
            </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
            <FormikInput
                name="customer_id"
                label="Send offer to"
                type="select"
                formik={formik}
                options={customers}
            />
            <FormikInput
                name="trip_id"
                label="Base offer on"
                type="select"
                formik={formik}
                options={trips}
            />
            <div className="row gx-1 mb-3">
                <div className="col-4">
                    <FormikInput
                        name="adults"
                        label="Adults (16+)"
                        formik={formik}
                        type="number"
                    />
                </div>
                <div className="col-4">
                    <FormikInput
                        name="youth"
                        label="Young adults (12-15)"
                        formik={formik}
                        type="number"
                    />
                </div>
                <div className="col-4">
                    <FormikInput
                        name="children"
                        label="Children (0-11)"
                        formik={formik}
                        type="number"
                    />
                </div>
            </div>
            <FormikInput
                name="departure"
                label="Departure date"
                formik={formik}
                type="date"
            />
            <button type="submit" className="btn btn-primary w-100">Create draft</button>
        </form>
        </>
    )
}