import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../providers/AuthProvider"
import { NotificationContext } from "../providers/NotificationProvider"
import { ToastContainer, toast } from 'react-toastify';
import Tiptap from "../components/Tiptap";
import Rte from "../components/Rte";
import { CSVLink, CSVDownload } from "react-csv";
import React from 'react';
import ReactPDF, { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

// Create Document Component
const MyDocument = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>Section #1</Text>
      </View>
      <View style={styles.section}>
        <Text>Section #2</Text>
      </View>
    </Page>
  </Document>
);
export default function Debug(){

    const { apiCall, downloadAttachment } = useContext(AuthContext)
    const { addNotification } = useContext(NotificationContext)

    const notify = async () => {
        addNotification({
            status: [1, 2, 3][Math.floor(Math.random() * 3)],
            title: ['Saved', 'Something went wrong', 'Woops', 'Warning', 'Random title', 'Finally', 'Wrong!', 'This is fine'][Math.floor(Math.random() * 8)],
            message: ['Everything is very good and you can relax', 'The object you just made changes to was deleted', 'Sorry to se you go, Dr. Evil', 'Oh behave! We can\'t have this in our home', 'I hope you\'re glad to see this message'][Math.floor(Math.random() * 5)]
        })
    }

    const [content, setContent] = useState('<p>Hello world</p>')

    useEffect(() => {
        console.log('Content updated', content)
    }, [content])

    const t = () => {
        
    }

    const test = async () => {
        const d = await apiCall({
          action: 'fortnoxApi',
          url: '3/invoicepayments?invoicenumber=4548',
          method: 'GET'
        })
        console.log(d)
        
    }

    const csvData = [
        ["firstname", "lastname", "email"],
        ["Ahmed", "Tomi", "ah@smthing.co.com"],
        ["Raed", "Labes", "rl@smthing.co.com"],
        ["Yezzi", "Min l3b", "ymin@cocococo.com"]
      ];
    const downloadPDF = () => {
        ReactPDF.renderToStream(<MyDocument />);
    }
    return (
        <div className="p-5">
            <div className="row mb-3">
                <div className="col-auto">
                <button className="btn btn-primary" onClick={() => test()}>Debug</button>
                </div>
                <div className="col-auto">
                <button className="btn btn-primary" onClick={() => notify()}>Notification</button>
                </div>
                
            </div>
            <div className="row mb-3">
                <div className="col-auto"><button className="btn btn-success" onClick={() => toast.success('Dette gikk jo veldig bra da')}>Toast</button></div>
                <div className="col-auto"><button className="btn btn-warning" onClick={() => toast.warning('Åneeej, vad skal du göra?')}>Toast</button></div>
                <div className="col-auto"><button className="btn btn-danger" onClick={() => toast.info('This is important information')}>Toast</button></div>
                <div className="col-auto"><button className="btn btn-info" onClick={() => toast.error('Cant create over 9000 bookings')}>Toast</button></div>
            </div>
            <div className="row">
                <div className="col-auto">
                    <Rte content={content} setContent={setContent} />
                </div>
                <div className="col-auto">
                    <CSVLink data={csvData}>Download me</CSVLink>
                </div>
            </div>
            <h3>PDF </h3>
            <button className="btn btn-sm btn-grey" onClick={() => downloadPDF()}>Download PDF</button>
            <PDFDownloadLink document={<MyDocument />} fileName="somename.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading document...' : 'Download now!'
      }
    </PDFDownloadLink>
            <MyDocument />
        </div>
    )
}