/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { createRef, useContext, useEffect, useRef, useState } from "react"
import { AuthContext } from "../providers/AuthProvider";
import {status} from '../components/Functions'

export default function FileUpload(props){

    const { apiCall } = useContext(AuthContext)

    const { files, setFiles } = props
    const container = createRef()
    const [uploading, setUploading] = useState(false)
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef(null);

    const handleChange = async e => {
        e.preventDefault();
        e.stopPropagation();
        
        if (e.target.files.length) {
            
            const fileList = e.target.files
            
            
            const tempFiles = []
            for (var i = 0; i < fileList.length; i++) {
                //if(files.length || !files.some(file => file.name === fileList[i].name)){
                    tempFiles.push(fileList[i])
                //}
            }

            await uploadFile(tempFiles)
            
        }
    }

    const uploadFile = async (filesToUpload) => {
        
        setUploading(true)
        const formData = new FormData();

        formData.append('action', 'uploadAttachment')
        filesToUpload.forEach((file) => {
            formData.append('files[]', file)
        })
        
        const d = await apiCall(formData)
        console.log('apiCall status', d)
        status(d)

        if(d.status === 1){
            let newFiles = files ? files : []
            d.data.forEach(f => {
                newFiles.push(f)
            })
            setFiles(newFiles)
        }
        setUploading(false)
                
    }

    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
      };

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        return;
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const fileList = e.dataTransfer.files
        
            
            
            let newFiles = files
            for (var i = 0; i < fileList.length; i++) {
                if(!files.some(file => file.name === fileList[i].name)){
                    const data = await uploadFile(fileList[i])
                    
                    if(data) newFiles.push(data)
                }
            }
            //console.log('newFiles', newFiles)
            //setFiles(newFiles)
        }

        setDragActive(false);
    }

    const removeFile = (id) => {
        setFiles(files.filter((f, i) => i !== id))
    }

    const FileIcon = ({type}) => {
        switch(type){
            case 'image/jpeg':
                return <FontAwesomeIcon icon={['fal', 'file-image']}  fixedWidth />

            default:
                return <FontAwesomeIcon icon={['fal', 'file-lines']}  fixedWidth />

        }
    }

    const FileItem = (props) => {
        const {  name, type, index, path } = props
        const { downloadAttachment, previewAttachment } = useContext(AuthContext)
        const [preview, setPreview] = useState(null)
        
        const download = async () => {
            await downloadAttachment({
                name: name,
                path: path,
                type: type
            })
        }

        return (
                <>
                <div className="file-item pointer">
                    <div className=" row align-items-center gx-1">
                        <div className="col-auto">
                            <FileIcon type={type} />
                        </div>
                        <div className="col" style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                            <p className="m-0">{name}</p>
                        </div>
                        <div className="col-auto">
                            <p className="text-success pointer mb-0 px-2" fixedWidth  onClick={() => previewAttachment(props)}><FontAwesomeIcon icon={['fal', 'magnifying-glass']} /></p>
                        </div>
                        <div className="col-auto">
                            <p className="text-primary pointer mb-0 px-2" fixedWidth  onClick={() => download()}><FontAwesomeIcon icon={['fal', 'download']} /></p>
                        </div>
                        <div className="col-auto">
                            <p className="text-danger pointer mb-0" fixedWidth  onClick={() => removeFile(index)}><FontAwesomeIcon icon={['fal', 'trash-alt']} /></p>
                        </div>
                    </div>
                </div>
                
                </>
            
        )
    }

    
    return (
        <div className={`${uploading ? 'loading-overlay' : ''} position-relative `}>

            <form id="form-file-upload" className={`file-upload-container ${dragActive ? 'drag-active' : ''}`} onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
                <div className="file-upload-text text-center" onClick={() => inputRef.current.click()}>
                   
                        <span className="fs-8">Drag & drop, or click to upload</span>
                    </div>
                { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
            </form>

            { files.length > 0 ? 
                (<div className="file-wrapper mt-2">
                    {
                        files.map((el, index) => {
                            return (
                                <FileItem 
                                    key={index}
                                    index={index}
                                    {...el}
                                />)
                            })  
                    }
            
                </div>)
            : null }
        </div>
    )
}
