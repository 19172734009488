import { useEffect, useState } from "react"
import Modal from "./Modal"
import DocViewer, { PDFRenderer, PNGRenderer, MSDocRenderer, VideoRenderer, JPGRenderer } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";

const ViewAttachment = ({ file }) => {
    const AudioRenderer = ({
        mainState: { currentDocument },
      }) => {
        console.log(currentDocument)
        if (!currentDocument) return null;
        
        return (
            <audio controls>
                <source src={currentDocument.fileData} type={currentDocument.mimeType} />
            </audio>
        )
      };
      
      AudioRenderer.fileTypes = ["audio/waw", "audio/x-waw", "audio/mp3"];
      AudioRenderer.weight = 1;
    

    const [modalOpen, setModalOpen] = useState(false)
    const [doc, setDoc] = useState(null)

    useEffect(() => {
        setModalOpen(true)
    }, [doc])

    useEffect(() => {
        if(file){
            setDoc(file)
        }
    }, [file])
    return (
        <Modal open={modalOpen} setOpen={setModalOpen} containerStyle={{minWidth: 800, maxWidth: '100vh', minHeight: '90vh'}}>
            { doc !== null ? 
                <DocViewer
                    documents={[
                        {
                            uri: window.URL.createObjectURL(doc.blob),
                            fileName: doc.name,
                            mimeType: doc.type
                        }
                    ]}
                    pluginRenderers={[PDFRenderer, PNGRenderer, VideoRenderer, JPGRenderer, AudioRenderer]}
                    config={{
                        noRenderer: { overrideComponent: MyNoRenderer }
                    }}
                
                />
            : null}
        </Modal>
    )
}

const MyNoRenderer = (props) => {
    const { document, fileName } = props
   
    const fileText = fileName || document?.fileType || "";

    if(document.mimeType.includes('audio')){
        return (
            <div className="d-flex flex-column flex-grow justify-content-center align-items-center py-5">
            <audio controls>
                <source src={document.uri} type={document.mimeType} />
            </audio>
            </div>
        )
    }

    if(document.mimeType.includes('officedocument')){
        return (
            <div className="d-flex flex-column flex-grow justify-content-center align-items-center">
It's not possible to preview Office documents.
            </div>
        )
    }
  
    if (fileText) {
      return <div>No Renderer Error! ({fileText})</div>;
    }
  
    return <div>Cannot preview this file.</div>;
};

export default ViewAttachment