/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { createRef, useContext, useEffect, useRef, useState } from "react"
import { AuthContext } from "../providers/AuthProvider";
import {status} from '../components/Functions'

export default function InvoiceFileInput(props){

    const { apiCall, downloadInvoice } = useContext(AuthContext)

    const { file, setFile } = props
    const container = createRef()
    const [uploading, setUploading] = useState(false)
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef(null);

    const handleChange = async e => {
        e.preventDefault();
        e.stopPropagation();
        
        if (e.target.files.length) {
            
            const fileList = e.target.files
            
            await uploadFile(fileList[0])
            
        }
    }

    const uploadFile = async (fileToUpload) => {
        
        setUploading(true)
        const formData = new FormData();

        formData.append('action', 'uploadInovice')
        
        formData.append('file', fileToUpload)
        
        
        const d = await apiCall(formData)
        console.log('apiCall status', d)
        status(d)

        if(d.status === 1){
            setFile(d.data)
        }
        setUploading(false)
                
    }

    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
      };

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        return;
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const fileList = e.dataTransfer.files
        
            
            
            let newFiles = files
            for (var i = 0; i < fileList.length; i++) {
                if(!files.some(file => file.name === fileList[i].name)){
                    const data = await uploadFile(fileList[i])
                    
                    if(data) newFiles.push(data)
                }
            }
            //console.log('newFiles', newFiles)
            //setFiles(newFiles)
        }

        setDragActive(false);
    }

    const removeFile = () => {
        setFile(null)
    }



    const download = async () => {
        await downloadInvoice(file)
    }


    return (
        <div className={`form-group mb-3 ${uploading ? 'loading-overlay' : ''}`}>
            <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
            <label className="fw-medium">File</label>
            <div className="form-control pointer"  onClick={() => {
                if(!file){
                    inputRef.current.click()
                }
            }}>
            
                <div className="row gx-2 align-items-center">
                    <div className="col-auto">
                        <FontAwesomeIcon icon={['far', 'file-text']} />
                    </div>
                    {file ? 
                    <>
                        <div className="col">
                            <span className="pointer" onClick={() => download()}>{file.name}</span> 
                        </div>
                        <div className="col-auto">
                            <FontAwesomeIcon className="pointer" onClick={() => removeFile()} icon={['far', 'trash-alt']} />
                        </div>
                    </>
                    
                    : 
                        <div className="col-auto">
                            <p className="hint-text m-0">Fetch invoice or click to upload manually</p>
                        </div>
                    }
                    
                        
                    
                </div>
            </div>
        </div>
    )

    return (
        <div className={`form-group mb-3 ${uploading ? 'loading-overlay' : ''}`}>
            <label className="fw-medium">File</label>
            <div className="input-group">
                <span class="input-group-text">
                    <FontAwesomeIcon icon={['far', 'file-text']} />
                </span>
                <input disabled={'disabled'}  type={'text'} value={''} placeholder={'Click to upload'} className={`form-control`} />
            </div>
        </div>
    )
    return (
        <div className={`${uploading ? 'loading-overlay' : ''} position-relative `}>

            <form id="form-file-upload" className={`file-upload-container ${dragActive ? 'drag-active' : ''}`} onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
                <div className="file-upload-text text-center" onClick={() => inputRef.current.click()}>
                   
                        <span className="fs-8">Drag & drop, or click to upload</span>
                    </div>
                { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
            </form>
        </div>
    )

    
}
