import React from "react"
export default function Input(props){
    const { 
        name,
        label,
        onChange,
        inputClass,
        error,
        type,
        placeholder,
        helperText,
        touched,
        disabled,
        prefix,
        mask,
        options
    } = props

    let {value} = props

    if(mask){
        //Funksjon for maskering av hvordan input skal se ut, eks.
        /**
            value = value.replace(/\D/g, "");
            value = value.replace(/(\d)(\d{2})$/, "$1 $2");
            value = value.replace(/(?=(\d{3})+(\D))\B/g, " ");
            return value;
         */
        value = mask(value)
    }

    if(type === 'select'){
        return (
            <div className={`form-group mb-3 ${error ? 'error' : null} ${touched && !error ? 'valid' : null}`}>
            {label ? <label className="fw-medium">{label}</label> : null}
            <div className="input-group">
                {prefix ? <span class="input-group-text">{prefix}</span> : null }
                
                <select disabled={disabled ? disabled : ''} name={name} value={value} onChange={onChange} placeholder={placeholder} className={`form-control ${inputClass}`}>
                    {placeholder ? <option selected disabled>{placeholder}</option> : null}
                    {options && options.length ? options.map((o, k) => <option key={k} value={o.value}>{o.label}</option>) : null}
                </select>
                
            </div>
            {error && helperText ? <small className="text-danger">{helperText}</small> : null}
        </div>
        )
    }

    return (
        <div className={`form-group mb-3 ${error ? 'error' : null} ${touched && !error ? 'valid' : null}`}>
            {label ? <label className="fw-medium">{label}</label> : null}
            <div className="input-group">
                {prefix ? <span class="input-group-text">{prefix}</span> : null }
                {
                    type === 'textarea' ? <textarea name={name} onChange={onChange} placeholder={placeholder} className={`form-control ${inputClass}`} value={value}></textarea>
                    : <input disabled={disabled ? disabled : ''}  type={type ? type : 'text'} name={name} value={value} onChange={onChange} placeholder={placeholder} className={`form-control ${inputClass}`} />
                }
            </div>
            {error && helperText ? <small className="text-danger">{helperText}</small> : null}
        </div>
    )
}